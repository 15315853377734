import React, { useState } from 'react';
import Layout from "../components/layout"
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Collapse } from 'react-collapse';
import Carousel from 'nuka-carousel';
import projects from '../projects'

const links = [
  {
    title: 'gregoiredavid.pro [arobase] gmail.com',
    // href: 'mailto:gregoiredavid.pro@gmail.com',
    href: '#',
  },
  {
    title: 'Twitter',
    href: 'https://twitter.com/gregoiredavid',
  },
    /*
  {
    title: 'Github',
    href: 'https://github.com/gregoiredavid',
  },
  */
  {
    title: 'Instagram',
    href: 'https://www.instagram.com/gregoiredavid29',
  },
];

const IndexPage = ({ data }) => {
  const [isOpened, setIsOpened] = useState(false);
  const images = data.allFile.nodes.reduce((acc, node) => {
    acc[node.name] = getImage(node.childImageSharp);
    return acc;
  });
              // <p>Originaire de Strasbourg, j'ai fait des études de philosophie à Paris et j'ai appris en parallèle la programmation informatique en autodidacte. Développeur web indépendant pendant une dizaine d'années, j'ai notamment assuré la direction technique de la refonte du site gouvernement.fr en 2014, avant de rejoindre France Culture comme responsable technique web.</p>
            // <p>Bonjour ! Je suis consultant, designer et développeur web, spécialisé dans la valorisation du patrimoine (musées, archives, archéologie) et la cartographie.</p>
  const buttonsSize = '60';
  const carouselOptions = {
      wrapAround: true,
      slidesToShow: ((typeof window !== "undefined") && window.innerWidth < 600) ? 1 : 3,
      defaultControlsConfig: {
          nextButtonText: '→',
          prevButtonText: '←',
          prevButtonStyle: {
              position: 'relative',
              width: buttonsSize + 'px',
              height: buttonsSize + 'px',
              'border-radius': buttonsSize + 'px',
              display: 'none',
          },
          nextButtonStyle: {
              position: 'relative',
              width: buttonsSize + 'px',
              height: buttonsSize + 'px',
              'border-radius': buttonsSize + 'px',
          },
          pagingDotsStyle: {
              display: 'none',
          },
      }
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <title>Grégoire David</title>
        <meta property="og:title" content="Grégoire David" />
        <meta property="og:site_name" content="Grégoire David" />
        <meta name="robots" content="noarchive" />
        <meta name="description" content="Designer et développeur web indépendant" />
        <meta property="og:description" content="Designer et développeur web indépendant" />
        <meta property="og:url" content="http://www.gregoiredavid.fr" />
        <meta name="viewport" content="width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&family=Roboto:wght@400;500&display=swap" rel="stylesheet" />
        <link rel="shortcut icon" href="data:image/x-icon;," type="image/x-icon" />
      </Helmet>


      <div className="about">
        <h1 className="site-title">Grégoire David</h1>
        <div className="site-meta">
          <div className="site-meta-about">
            <p className="site-subtitle">Outils numériques & cartographie</p>
            {/*<p className="site-subtitle">Projets numériques & agitation culturelle</p>*/}
            {/*<p>Bonjour ! Je suis designer, développeur et chef de projet indépendant, basé à Brest.</p>*/}
            <Collapse isOpened={isOpened}>
              <p>Bonjour ! J'ai 34 ans, je suis développeur et chef de projet indépendant. Je travaille sur tous types de projets avec une prédilection pour la médiation culturelle et la cartographie.</p>
              {/* <p>Originaire de Strasbourg, j'ai fait des études de philosophie à Paris et j'ai appris en parallèle la programmation informatique en autodidacte.</p> */}
              {/* Après une dizaine d'années comme développeur web indépendant, j'ai assuré la direction technique de la refonte du site gouvernement.fr en 2014 puis j'ai rejoint la radio France Culture comme responsable technique web.</p>*/}
              <p>Originaire de Strasbourg, j'ai suivi des études de philosophie et d'histoire de l'art et j'ai appris la programmation informatique en autodidacte. Après une dizaine d'années comme développeur web indépendant j'ai été chef de projet technique pour le Service d'Information du Gouvernement (gouvernement.fr) puis la radio France Culture.</p>
              <p>À nouveau freelance depuis quelques années, je vis et je travaille à Brest où je suis impliqué comme bénévole dans l'organisation de festivals autour du conte et des arts de la rue.</p>
              {/*<p>Je travaille à nouveau en freelance depuis 2017.</p>*/}
              {/*<p>Aujourd'hui à nouveau en freelance, je vis et je travaille à Brest, en Bretagne. Diplômé en histoire de l'art et en archéologie, je me consacre également à la médiation et la valorisation du patrimoine via le numérique.</p>*/}
            </Collapse>
            {isOpened && <div className="more-link" onClick={() => setIsOpened(!isOpened)}>↑ Replier</div>}
            {!isOpened && <div className="more-link" onClick={() => setIsOpened(!isOpened)}>↓ En savoir plus</div>}
          </div>
          <div className="site-links mono">
            {links.map(l => <div>↗ {' '}<a className="link" target="_blank" href={l.href}>{l.title}</a></div>)}
          </div>
        </div>
      </div>
      {/* <h2>Développeur et chef de projet technique indépendant</h2> */}
      <h2>Plateformes numériques & sites web</h2>
      {/*
      <p className="subtitle">Depuis une dizaine d’années j’accompagne des organisations tailles variées dans la conception de leurs outils numériques</p>
      */}
      {/*
      <ul>
          <li>Conseil en architecture technique et AMOA</li>
          <li>Développement web fullstack</li>
          <li>Design d’interface</li>
      </ul>
      */}
      <Carousel {...carouselOptions}>
        {projects.filter(p => p.category === 'dev').map(project => (
          <Link
            to={project.id}
            key={project.id}
            className="project"
          >
            <GatsbyImage
              className="project-img"
              image={images[project.id]}
              alt={project.title}
              width="200"
            />
            <div className="heading">{project.title} →</div>
            <div className="project-text">{project.teaser}</div>
          </Link>
        ))}
      </Carousel>
      <h2>Musées & médiation du patrimoine</h2>
      {/*
      <p className="subtitle">En parallèle de mon activité généraliste je suis impliqué dans plusieurs projets de médiation du patrimoine via le numérique</p>
      */}
      <Carousel {...carouselOptions}>
        {projects.filter(p => p.category === 'mediation').map(project => (
          <Link
            to={project.id}
            key={project.id}
            className="project"
          >
            <GatsbyImage
              className="project-img"
              image={images[project.id]}
              alt={project.title}
              width="200"
            />
            <div className="heading">{project.title} →</div>
            <div className="project-text">{project.teaser}</div>
          </Link>
        ))}
      </Carousel>
      <h2>Cartographie</h2>
      <Carousel {...carouselOptions}>
        {projects.filter(p => p.category === 'carto').map(project => (
          <Link
            to={project.id}
            key={project.id}
            className="project"
          >
            <GatsbyImage
              className="project-img"
              image={images[project.id]}
              alt={project.title}
              quality={90}
            />
            <div className="heading">{project.title} →</div>
            <div className="project-text">{project.teaser}</div>
          </Link>
        ))}
      </Carousel>
      <div className="autres-references">
        <h2>Autres références</h2>
        <p className="mono">Publicis, Goodyear, Ministère des Affaires Étrangères, Altedia, Butagaz, Petit Bateau, Nicolas (les vins), Mouvement pour le Planning Familial, Ville de Pantin, Syndex, Descamps, Association Villes Internet, SNES-FSU, etc...</p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

export default IndexPage
